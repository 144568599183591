<template>
    <div class="mt-lg-3 mt-1 pt-3 pb-0 px-3">

        <b-card v-if="$OEM === 'things'" class="card-material" style="max-width: 60rem;">
            <h4>
                <a :href="rev1" target="_blank">TDC Manual (Rev. 1)</a>
                <pre><i class="small" style="color: grey;">13 November 2018</i></pre>
            </h4>
            <p class="card-text">
                The Temperature Difference Controller ESDP TDC V1 facilitates efficient use and
                function control of your solar or heating system. The device is impressive most of
                all for its functionality and simple, almost self-explanatory operation.
                <br><br><span style="color: grey;"><i class="fa fa-file-pdf-o"></i> PDF ~4.3MB</span>
            </p>
        </b-card>

        <b-card v-if="$OEM === 'things'" class="card-material" style="max-width: 60rem;">
            <h4>
                <a :href="rev2" target="_blank">TDC Manual (Rev. 2)</a>
                <pre><i class="small" style="color: grey;">9 May 2019</i></pre>
            </h4>
            <p class="card-text">
                The Temperature Difference Controller ESDP TDC V2 facilitates efficient use and
                function control of your solar or heating system. The device is impressive most of
                all for its functionality and simple, almost self-explanatory operation.
                <br><br><span style="color: grey;"><i class="fa fa-file-pdf-o"></i> PDF ~3.3MB</span>
            </p>
        </b-card>

        <b-card v-if="$OEM === 'pleion'" class="card-material" style="max-width: 60rem;">
            <h4>
                <a :href="pleionTDCLink" target="_blank">{{ $t('documents.tdcManual') }}</a>
                <pre><i class="small" style="color: grey;">{{ $t('documents.manualReleaseDatePleion') }}</i></pre>
            </h4>
            <p class="card-text">
                {{ $t('documents.manualDescriptionPleion') }}
                <br><br><span style="color: grey;"><i class="fa fa-file-pdf-o"></i> PDF ~3.3MB</span>
            </p>
        </b-card>

        <b-card v-if="$OEM === 'things'" class="card-material" style="max-width: 60rem;">
            <h4 class="pb-2">
                <h4>{{ $t('documents.createYourFirstDevice') }}</h4>
            </h4>
            <p class="card-text pb-2">
                {{ $t('documents.createYourFirstDeviceDesc') }}
            </p>
            <p class="card-text" ref="youtubeContainer">
                <youtube v-if="youtubeWidth" :width="youtubeWidth" :height="youtubeHeight" :video-id="'a4HpAy1BtVc'" :resize='true'
                         :fitParent="true"/>
            </p>
        </b-card>

        <b-card class="card-material" style="max-width: 60rem;">
            <h4 class="pb-2">
                <h4>{{ $t('documents.connectDeviceToWifi') }}</h4>
            </h4>
            <p class="card-text pb-2">
                {{ $t('documents.connectDeviceToWifiDesc') }}
            </p>
            <p class="card-text" ref="youtubeContainer">
                <youtube v-if="youtubeWidth" :width="youtubeWidth" :height="youtubeHeight" :video-id="connectToWiFiVideoId"/>
            </p>
        </b-card>

        <b-card class="card-material" style="max-width: 60rem;">
            <h4 class="pb-2">
                <h4>{{ $t('documents.linkDeviceToAccount') }}</h4>
            </h4>
            <p class="card-text pb-2">
                {{ $t('documents.linkDeviceToAccountDesc') }}
            </p>
            <p class="card-text" ref="youtubeContainer">
                <youtube v-if="youtubeWidth" :width="youtubeWidth" :height="youtubeHeight" :video-id="linkDeviceToAccountVideoId" :resize='true'
                         :fitParent="true"/>
            </p>
        </b-card>

        <b-card class="card-material" style="max-width: 60rem;">
            <h4 class="pb-2">
                <h4>{{ $t('documents.setupVariantAndModules') }}</h4>
            </h4>
            <p class="card-text pb-2">
                {{ $t('documents.setupVariantAndModules') }}
            </p>
            <p class="card-text" ref="youtubeContainer">
                <youtube v-if="youtubeWidth" :width="youtubeWidth" :height="youtubeHeight" :video-id="setupVariantAndModulesVideoId" :resize='true'
                         :fitParent="true"/>
            </p>
        </b-card>
    </div>
</template>

<script>
    import {Youtube} from 'vue-youtube'

    export default {
        name: "Documents",

        components: {
            Youtube
        },

        data() {
            return {
                rev1: 'https://things.al/products/pdf/tdc_rev_1.pdf',
                rev2: 'https://things.al/products/pdf/tdc_rev_2.pdf',
                youtubeWidth: null,
                youtubeHeight: null,
            }
        },

        computed: {
            pleionTDCLink() {
                return this.$i18n.locale === 'en' ? 'http://pleion.things.al:9876/tdc_files/pleion_tdc_manual_en.pdf' : 'http://pleion.things.al:9876/tdc_files/pleion_tdc_manual_it.pdf';
            },

            connectToWiFiVideoId() {
                if (this.$OEM === 'things') return 'K1elSuzjI7E';
                else return this.$i18n.locale === 'en' ? 'n4Xn8eQ0YWw' : 'TfwPPBcz-Mg';
            },

            linkDeviceToAccountVideoId() {
                if (this.$OEM === 'things') return 'Pc1hdd7OdNI';
                else return this.$i18n.locale === 'en' ? 'wB6cn07xFN0' : 'lQ-jToQ9y3s';
            },

            setupVariantAndModulesVideoId() {
                if (this.$OEM === 'things') return '-LT3j6bqqm8';
                else return this.$i18n.locale === 'en' ? 'KGTVMrPTP6A' : 'YYIp1-H70YQ';
            }
        },

        mounted() {
            this.youtubeWidth = this.$refs.youtubeContainer.clientWidth; //we get width from container to prevent glitch when reloading window
            this.youtubeHeight = this.youtubeWidth / 1.777777778;
        }
    }
</script>

<style scoped>
</style>